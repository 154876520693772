import React from 'react';
import ElementWrapper from './ElementWrapper';
import Image from 'react-bootstrap/Image';

const ImageTag = ({ element, dragHandleProps, handleDelete, handleEdit }) => {
  const {title, alt_text, caption, image_file, ...rest} = element.serialized_content;

  return (
    <ElementWrapper element={element} elementClass='element-image' dragHandleProps={dragHandleProps} handleEdit={handleEdit} handleDelete={handleDelete}>
      <p>{title}</p>
      <Image src={image_file.url} alt={alt_text} />
      {caption && <p>{caption}</p>}
    </ElementWrapper>
  );
}

export default ImageTag;
