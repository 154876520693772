import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import SelectTags from './SelectTags';
import { updateFormValue, cancelEdit, createForm, updateForm } from 'actions/form.base.actions';
import ContentUploader from './ContentUploader';

class VideoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false }
    this.titleRef = React.createRef();
    this.descriptionRef = React.createRef();
  }

  handleCreateSubmit = (e) => {
    e.preventDefault();
    this.props.createForm(this.props.form, this.props.details)
  }

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.updateForm(this.props.form, this.props.details)
  }

  uploadingVideo = () => this.setState({ uploading: true });
  uploadedVideo = blob => {
    this.props.handleFormUpdate('video_file', blob);
    this.setState({ uploading: false });
  }
  removedVideo = () => this.props.handleFormUpdate('video_file', undefined);

  render() {
    const { form, element, values = {}, editingElement=false, errors = {}, handleFormUpdate, cancelEdit } = this.props;
    return (
      <form noValidate onSubmit={editingElement === true ? this.handleUpdateSubmit : this.handleCreateSubmit} id='video-form'>
        <div className="card mb-3">
          <div className="card-header">{editingElement === true ? 'Edit video' : 'Add video'}</div>
          <div className="card-body">
            <div className="mb-0 mt-3">
              <Row>
                <Col md="6">
                  <p>Tags</p>
                  <SelectTags element={element}></SelectTags>

                  <Form.Group controlId="video-title" className="mt-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control ref={this.titleRef} type="string" placeholder="Enter video title" value={values.title} onChange={(e) => handleFormUpdate('title', e.target.value)} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                    

                  <Form.Group controlId="video-description" className="mt-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control ref={this.descriptionRef} as="textarea" rows="3" placeholder="Enter video description" value={values.description} onChange={(e) => handleFormUpdate('description', e.target.value)} />
                  </Form.Group>

                </Col>
                <Col md="6">
                  <ContentUploader
                    content={values.video_file}
                    uploadingContent={this.uploadingVideo}
                    uploadedContent={this.uploadedVideo}
                    removedContent={this.removedVideo}
                    acceptedFormats="video/mp4,video/quicktime,video/x-msvideo"
                    fileTypes={['.mp4', '.mov', '.avi']} />
                  <Form.Group controlId="video-file">
                    <Form.Control type="hidden" isInvalid={!!errors.video_file} />
                    <Form.Control.Feedback type="invalid">
                      {errors.video_file}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card-footer">
            <Row>
              <Col md="6">
                <Button variant="link" onClick={cancelEdit}>Cancel</Button>
              </Col>
              <Col md="6" className="d-flex justify-content-end">
                <Button variant="primary" type='submit' disabled={this.state.uploading}>{editingElement === true ? 'Update video' : 'Add video'}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    createForm: createForm,
    updateForm: updateForm,
    cancelEdit: cancelEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoForm)
