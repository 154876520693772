import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const DraggableElement = ({ element, index, children}) => {
  return (
    <Draggable draggableId={`${element.id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='element-wrapper'>
          { React.cloneElement(children, { dragHandleProps: provided.dragHandleProps }) }
        </div>
      )}
    </Draggable>
  );
}

export default DraggableElement;
