import React from 'react';
import { connect } from 'react-redux';
import SubheadingForm from './SubheadingForm';
import ParagraphForm from './ParagraphForm';
import VideoForm from './VideoForm';
import ImageForm from './ImageForm';

const ItemForm = ({ itemType, element, values, editingElement=false }) => {

  const ContentTypes = {
    'Subheading': SubheadingForm,
    'Paragraph' : ParagraphForm,
    'Video'     : VideoForm,
    'Image'     : ImageForm
  }
  const FormComponentType = ContentTypes[itemType];

  return (
    <FormComponentType values={values} editingElement={editingElement} element={element}></FormComponentType>
  );
}

const mapStateToProps = (state) => {
  return {
    itemType: state.form.itemType,
    values: state.form.values
  };
};

export default connect(mapStateToProps, null)(ItemForm)
