import { saveError, saveSuccess } from 'actions/form.base.actions';
import { createContent, updateContent } from 'services/api/content_requests';
import { videoValidations } from 'services/validations/video';

const canHandleAction = (action) => {
  if (action.type !== 'CREATE_FORM' && action.type !== 'UPDATE_FORM') {
    return false;
  }
  return (action.form.itemType === 'Video');
};

export const saveVideoMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a video
  if (!canHandleAction(action)) {
    return next(action)
  }

  const state = getState();

  let video_signed_id = state.form.values.video_file.signed_id;
  let video_body = {
    ...state.form.values,
    video_file: video_signed_id
  }

  const body = {
    video: video_body,
    tag_ids: state.form.tagIds || [],
    new_tag_names: state.form.newTagNames || []
  }

  let request;
  if (action.type === 'CREATE_FORM') {
    request = createContent(state.technique.id, state.form.categoryToAddItemTo.id, 'videos', body)
  } else if (action.type === 'UPDATE_FORM') {
    request = updateContent(state.technique.id, state.form.selectedCategoryId, 'videos', state.form.selectedElementId, body)
  }

  request
    .then(res => res.json())
    .then(res => dispatch(saveSuccess(res)))
    .catch(err => dispatch(saveError(err)));
};

export const videoFormValidationMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a video
  if (!canHandleAction(action)) {
    return next(action)
  }

  const video = action.form;

  let errors = { ...videoValidations(video.values) }
  const anyErrors = Object.values(errors).some(errs => errs.length > 0);

  if (anyErrors) {
    dispatch(saveError(errors))
  } else {
    return next(action)
  }
};