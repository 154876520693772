import React from 'react';
import { connect } from 'react-redux';
import Category from './Category';

const Technique = ({ technique }) => {
  return (
    <div className="mt-3">
      <h1 className="mb-3">{technique.title}</h1>
      <div className='technique-toolbar'>
        <a href='/content_manager/techniques' className='btn btn-outline-secondary btn-sm'>
          <i className='fas fa-chevron-left mr-1'></i>
          Back
        </a>

        <a href={`/techniques/${technique.slug}/categories/${technique.categories[0].id}`} className='btn btn-outline-primary btn-sm' target='_blank' rel='noopener noreferrer'>
            <i className='fad fa-presentation mr-1'></i>
          Preview
        </a>
      </div>
      {technique.categories.map(category => <Category category={category} key={`category_component_${category.id}`}></Category> )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    technique: state.technique
  };
};

export default connect(mapStateToProps)(Technique)
