export const TagsReducer = (state = [], action) => {
  
  switch (action.type) {
    case 'SAVE_SUCCESS': {
      return action.savedObject.available_tags.map((tag) => { return ({ label: tag.name, value: tag.id }); })
    }
  }

  return state;
}
 