export const updateTags = (tags) => ({
  type: 'UPDATE_TAGS',
  tagIds: tags.map((tag) => { return tag.value })
});

export const updateNewTags = (tags) => ({
  type: 'UPDATE_NEW_TAGS',
  tagNames: tags.map((tag) => { return tag.label })
});



