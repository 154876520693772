import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import SelectTags from './SelectTags';
import { updateFormValue, cancelEdit, createForm, updateForm } from 'actions/form.base.actions';
import ContentUploader from './ContentUploader';

class ImageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false }
    this.titleRef = React.createRef();
    this.alt_textRef = React.createRef();
    this.captionRef = React.createRef();
  }

  handleCreateSubmit = (e) => {
    e.preventDefault();
    this.props.createForm(this.props.form, this.props.details)
  }

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.updateForm(this.props.form, this.props.details)
  }

  uploadingImage = () => this.setState({ uploading: true });
  uploadedImage = blob => {
    this.props.handleFormUpdate('image_file', blob);
    this.setState({ uploading: false });
  }
  removedImage = () => this.props.handleFormUpdate('image_file', undefined);

  render() {
    const { form, element, values = {}, editingElement=false, errors = {}, handleFormUpdate, cancelEdit } = this.props;
    return (
      <form noValidate onSubmit={editingElement === true ? this.handleUpdateSubmit : this.handleCreateSubmit} id='image-form'>
        <div className="card mb-3">
          <div className="card-header">{editingElement === true ? 'Edit image' : 'Add image'}</div>
          <div className="card-body">
            <div className="mb-0 mt-3">
              <Row>
                <Col md="6">
                  <p>Tags</p>
                  <SelectTags element={element}></SelectTags>

                  <Form.Group controlId="image-title" className="mt-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control ref={this.titleRef} type="string" placeholder="Enter image title" value={values.title} onChange={(e) => handleFormUpdate('title', e.target.value)} isInvalid={!!errors.title} />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="image-alt-text" className="mt-3">
                    <Form.Label>Alt Text</Form.Label>
                    <Form.Control ref={this.alt_textRef} type="string" placeholder="Enter image alt text" value={values.alt_text} onChange={(e) => handleFormUpdate('alt_text', e.target.value)} isInvalid={!!errors.alt_text} />
                    <Form.Control.Feedback type="invalid">
                      {errors.alt_text}
                    </Form.Control.Feedback>
                  </Form.Group>
                    

                  <Form.Group controlId="image-caption" className="mt-3">
                    <Form.Label>Caption</Form.Label>
                    <Form.Control ref={this.captionRef} as="textarea" rows="3" placeholder="Enter image caption" value={values.caption} onChange={(e) => handleFormUpdate('caption', e.target.value)} />
                  </Form.Group>

                </Col>
                <Col md="6">
                  <ContentUploader 
                    content={values.image_file} 
                    uploadingContent={this.uploadingImage} 
                    uploadedContent={this.uploadedImage} 
                    removedContent={this.removedImage}
                    acceptedFormats="image/jpeg,image/png,image/svg+xml"
                    fileTypes={['.jpeg', '.png', '.svg']} />
                  <Form.Group controlId="image-file">
                    <Form.Control type="hidden" isInvalid={!!errors.image_file} />
                    <Form.Control.Feedback type="invalid">
                      {errors.image_file}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card-footer">
            <Row>
              <Col md="6">
                <Button variant="link" onClick={cancelEdit}>Cancel</Button>
              </Col>
              <Col md="6" className="d-flex justify-content-end">
                <Button variant="primary" type='submit' disabled={this.state.uploading}>{editingElement === true ? 'Update image' : 'Add image'}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    createForm: createForm,
    updateForm: updateForm,
    cancelEdit: cancelEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageForm)
