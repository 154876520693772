import { present, runValidations } from '../base.validations';

const attributeValidators = {
  title: [{ validation: present}],
  video_file: [{ validation: present}]
}

export function videoValidations(video) {
  return runValidations(video, attributeValidators);
}
