import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { handleAddNewItem, handleMoveElement, handleDeleteElement, handleEditElement } from 'actions/techniques'
import { getNonce } from 'services/api/nonce_helper';

import NewItemDropdown from './NewItemDropdown';
import Paragraph from './Paragraph';
import Subheading from './Subheading';
import Video from './Video';
import ImageTag from './ImageTag';
import ItemForm from './ItemForm';
import DraggableElement from './DraggableElement';

const ContentTypes = {
  'Subheading': Subheading,
  'Paragraph': Paragraph,
  'Video': Video,
  'Image': ImageTag
}

const Category = ({ category, categoryToAddItemTo, selectedElementId, addingElement, handleAddNewItemAction, handleMoveElementAction, handleDeleteElementAction, handleEditElementAction}) => {

  const handleNewItemClick = (itemType) => {
    handleAddNewItemAction(category, itemType)
  }
  
  const handleDeleteElementClick = (elementID) => {
    handleDeleteElementAction(category.id, elementID);
  }

  const handleEditElementClick = (elementID) => {
    handleEditElementAction(category.id, elementID);
  }

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    handleMoveElementAction(category.id, result.draggableId, result.source.index, result.destination.index);
  }

  const renderElement = (element, index, selectedElementId) => {
    if (element.id == selectedElementId) {
      return (
        <div key={element.id}>
          <ItemForm editingElement={true} element={element}></ItemForm>
        </div>
      );
    }
    else {
      return (
        <DraggableElement key={element.id} index={index} element={element} >
          <ComponentSwitcher type={element.content_type} element={element} selectedElementId={selectedElementId} handleDelete={handleDeleteElementClick} handleEdit={handleEditElementClick} />
        </DraggableElement>
      );
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} nonce={getNonce()}>
      <div id={`${category.identifier}_category`}>
        <h2 className="component-category">{category.title}</h2>
        <Droppable droppableId={`${category.identifier}_droppable_area`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={`element-list ${selectedElementId !== undefined ? 'editing' : ''} ${category.identifier}_category_droppable`}>
              {
                category.elements.map((element, index) => (
                  renderElement(element, index, selectedElementId)
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <NewItemDropdown handleAddNewItem={handleNewItemClick} />
        {addingElement && (categoryToAddItemTo.id == category.id) && <ItemForm></ItemForm>}
      </div>
    </DragDropContext>
  );
}

const ComponentSwitcher = ({ element, selectedElementId, dragHandleProps, handleDelete, handleEdit }) => {
  let ComponentType = ContentTypes[element.content_type];

  return <ComponentType element={element} selectedElementId={selectedElementId} dragHandleProps={dragHandleProps} handleDelete={handleDelete} handleEdit={handleEdit}/>
}

const mapStateToProps = (state) => {
  return {
    categoryToAddItemTo: state.form.categoryToAddItemTo,
    addingElement: state.form.addingElement,
    selectedElementId: state.form.selectedElementId
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleAddNewItemAction:    handleAddNewItem,
    handleMoveElementAction:   handleMoveElement,
    handleDeleteElementAction: handleDeleteElement,
    handleEditElementAction:   handleEditElement
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Category)
