import { baseFormReducer } from './form.base.reducer';

export const FormReducer = (state = { addingElement: false}, action) => {
  state = baseFormReducer(state, action);

  const formDefaults = (itemType) => {
    switch(itemType) {
      case 'Subheading': {
        return {
          title: ''
        }
      }
      case 'Paragraph': {
        return {
          text: ''
        }
      }
      case 'Video': {
        return {
          title: '',
          description: '',
          video_file: undefined
        }
      }
      case 'Image': {
        return {
          title: '',
          alt_text: '',
          caption: '',
          image_file: undefined
        }
      }
    }
  }

  const formValues = (element) => {
    switch (element.content_type) {
      case 'Subheading': {
        return {
          title: element.content.title
        }
      }
      case 'Paragraph': {
        return {
          text: element.content.text
        }
      }
      case 'Video': {
        return {
          title: element.content.title,
          description: element.content.description,
          video_file: element.serialized_content.video_file
        }
      }
      case 'Image': {
        return {
          title: element.content.title,
          alt_text: element.content.alt_text,
          caption: element.content.caption,
          image_file: element.serialized_content.image_file
        }
      }
    }
  }

  switch (action.type) {
    case 'UPDATE_TAGS': {
      let newState = { ...state };
      newState['tagIds']= action.tagIds;
      return newState;
    }

    case 'UPDATE_NEW_TAGS': {
      let newState = { ...state };
      newState['newTagNames'] = action.tagNames;
      return newState;
    }

    case 'CLICK_ADD_NEW_ITEM': {
      return {
        itemType: action.itemType,
        categoryToAddItemTo: action.category,
        addingElement: true,
        values: formDefaults(action.itemType)
      };
    }

    case 'SAVE_SUCCESS': {
      return {
        ...state,
        itemType: undefined,
        addingElement: false,
        selectedCategoryId: undefined,
        selectedElementId: undefined
      };
    }

    case 'SELECT_ELEMENT_FOR_EDIT': {
      const { categoryID, element } = action;

      return {
        ...state,
        itemType: element.content_type,
        values: formValues(element),
        selectedCategoryId: categoryID,
        selectedElementId: element.id
      };
    }

    case 'CANCEL_EDIT': {
      let newState = { ...state };
      newState.selectedElementId = undefined;
      return newState;
    }
  }

  return state;
}
