import { present, runValidations } from '../base.validations';

const attributeValidators = {
  title: [{ validation: present }],
  alt_text: [{ validation: present }],
  image_file: [{ validation: present }]
}

export function imageValidations(image) {
  return runValidations(image, attributeValidators);
}
