import React, { useEffect } from 'react';
require('highlight');
import hljs from 'highlight.js';
import $ from 'jquery';
import popover from 'bootstrap/js/dist/popover';

import ElementWrapper from './ElementWrapper';

const Paragraph = ({ element, dragHandleProps, handleDelete, handleEdit }) => {

  const paragraphText = () => {
    return { __html: element.content.text.replace(/{ def title='(.*?)' text='(.*?)' }/g, "<a class='definition-tag' data-toggle='popover' data-placement='top' title='$1' data-content='$2'>$1</a>") }
  }

  useEffect(() => {
    
    document.querySelector('#element-content-'+ element.id).querySelectorAll('pre').forEach((block) => {
      hljs.highlightBlock(block);
    })

    $('[data-toggle="popover"]').popover({
      trigger: 'hover'
    });
  });

  return (
    <ElementWrapper element={element} elementClass='element-paragraph' dragHandleProps={dragHandleProps} handleEdit={handleEdit} handleDelete={handleDelete}>
      <div dangerouslySetInnerHTML={paragraphText()}></div>
    </ElementWrapper>
  );
}

export default Paragraph;
