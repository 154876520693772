import React from 'react';
import ElementWrapper from './ElementWrapper';
import ReactPlayer from 'react-player'

const Video = ({ element, dragHandleProps, handleDelete, handleEdit }) => {
  return (
    <ElementWrapper element={element} elementClass='element-video' dragHandleProps={dragHandleProps} handleEdit={handleEdit} handleDelete={handleDelete}>
      <p>{element.serialized_content.title}</p>
      <ReactPlayer url={element.serialized_content.video_file.url} controls width='80%' height='auto' />
      {element.serialized_content.description && <p>{element.serialized_content.description}</p>}
    </ElementWrapper>
  );
}

export default Video;
