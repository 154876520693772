export const TechniqueReducer = (state = [], action) => {
  const moveElement = (categoryID, initialPosition, endPosition) => {
    let newState = { ...state }
    let newCategory = newState['categories'].filter(category => category.id == categoryID)[0]
    let newElements = newCategory['elements']

    const [moved] = newElements.splice(initialPosition, 1);
    newElements.splice(endPosition, 0, moved);

    return newState;
  }

  switch (action.type) {
    case 'SAVE_SUCCESS': {
      let categories = state.categories.map((category) => {
        if (category.id === action.savedObject.id) {
          return action.savedObject;
        } else {
          return category;
        }
      });

      return {
        ...state,
        categories: categories
      }
    }

    case 'MOVE_ELEMENT': {
      const { categoryID, initialPosition, endPosition } = action;
      return moveElement(categoryID, initialPosition, endPosition);
    }

    case 'DELETE_ELEMENT_SUCCESS': {
      const { categoryID, elementID } = action;
      let categories = state.categories;
      let categoryIndex = categories.findIndex(category => category.id == categoryID);

      let newState = {
        ...state,
        categories: [
          ...categories.slice(0, categoryIndex),
          {
            ...categories[categoryIndex],
            elements: categories[categoryIndex].elements.filter(element => element.id != elementID)
          },
          ...categories.slice(categoryIndex + 1)
        ]
      }
      return newState;
    }

    case 'MOVE_ELEMENT_FAILURE': {
      const { categoryID, initialPosition, endPosition } = action;
      return moveElement(categoryID, initialPosition, endPosition);
    }

    case 'SELECT_ELEMENT_FOR_EDIT': {
      const { categoryID, element } = action;

      let categories = state.categories;
      let categoryIndex = categories.findIndex(category => category.id == categoryID);

      let newState = { ...state };
      newState.categories[categoryIndex].selectedElementForEdit = element;

      return newState;
    }

    case 'CANCEL_EDIT': {
      let categories = state.categories;
      let newCategories = categories.map(category => Object.assign(category, { selectedElementForEdit: undefined }));
      let newState = {
        ...state,
        categories: newCategories
      }

      return newState;
    }

  }

  return state;
}
