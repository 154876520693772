import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NonceProvider, components } from 'react-select';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import { updateTags, updateNewTags } from 'actions/tags';
import { getNonce } from 'services/api/nonce_helper';
import 'stylesheets/select2';

const Option = props => {
  return (
    <div className='select-tag-option'>
      <components.Option {...props} />
    </div>
  );
};

class SelectTags extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      options: props.options,
      selectedOptions: this.selectedOptionsFor(props.element)
    }
  }

  handleChange = (tags, meta) => {
    const currentTags = tags || [];
    const existingTags = currentTags.filter(tag => !tag.__isNew__);
    const newTags = currentTags.filter(tag => tag.__isNew__);
    
    this.setState({ ...this.state, selectedOptions: currentTags});
    this.props.updateTags(existingTags);
    this.props.updateNewTags(newTags);
  }

  selectedOptionsFor = (element) => {
    if (element) {
      return element.tags.map((tag) => { return { label: tag.name, value: tag.id } });
    } else {
      return [];
    }
  }

  render() {
    return(
      <NonceProvider nonce={getNonce()}>
        <Creatable components={{ Option }} options={this.state.options} isMulti onChange={this.handleChange} value={this.state.selectedOptions} className='select-tags' classNamePrefix='select-tags-inner'/>
      </NonceProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    options: state.tags
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateTags: updateTags,
    updateNewTags: updateNewTags
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTags)