import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from "dropzone";
import DropzoneComponent from 'react-dropzone-component';
import { DirectUpload } from "@rails/activestorage"

import 'react-dropzone-component/styles/filepicker';
import 'dropzone/dist/min/dropzone.min.css';



export default class ContentUploader extends PureComponent {
  djsConfig = acceptedFormats => {
    return {
      acceptedFiles: acceptedFormats,
      autoProcessQueue: false,
      uploadMultiple: false,
      addRemoveLinks: true
    }
  }

  componentConfig = fileTypes => {
    return {
      iconFiletypes: fileTypes,
      showFiletypeIcon: true,
      maxFiles: 1,
      postUrl: 'no-url'
    }
  }
  
  showPreview = content => {
    if (content == null) return;

    let mockFile = {
      name: content.name,
      size: content.byte_size,
      dataURL: content.url,
    };

    this.myDropzone.files.push(mockFile);
    this.myDropzone.emit("addedfile", mockFile);
    this.myDropzone.emit("complete", mockFile);
  }

  removePrevAndAddNew = content => {
    if (this.myDropzone.files.length > 1) {
      let prevFile = this.myDropzone.files[0];
      this.myDropzone.emit('removedfile', prevFile);
    }

    this.uploadContent(content);
  }

  // Based on: https://web-crunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone

  uploadContent = (content) => {
    this.file = content;

    const url = '/rails/active_storage/direct_uploads';
    const upload = new DirectUpload(this.file, url, this);

    this.props.uploadingContent();

    upload.create((error, blob) => {
      if (error) {
        this.emitDropzoneError(error);
      } else {
        this.props.uploadedContent(blob);
        this.emitDropzoneSuccess();
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.file.previewTemplate.querySelector(".dz-upload").style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.myDropzone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.myDropzone.emit("error", this.file, error);
    this.myDropzone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.myDropzone.emit("success", this.file);
    this.myDropzone.emit("complete", this.file);
  }

  render() {
    const { content } = this.props;
    const eventHandlers = {
      init: dropzone => {
        this.myDropzone = dropzone;
        this.showPreview(content);
      },
      addedfile: content => this.removePrevAndAddNew(content),
      removedfile: () => this.props.removedContent()
    }

    return (
      <DropzoneComponent
        config={this.componentConfig(this.props.fileTypes)}
        eventHandlers={eventHandlers}
        djsConfig={this.djsConfig(this.props.acceptedFormats)}
      />
    );
  }
}

ContentUploader.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    byte_size: PropTypes.integer,
    url: PropTypes.string
  }),
  uploadedContent: PropTypes.func.isRequired,
  removedContent:  PropTypes.func.isRequired,
  acceptedFormats: PropTypes.string,
  fileTypes:       PropTypes.array
};