export const newItem = () => ({
  type: 'NEW_ITEM'
});

export const handleAddNewItem = (category, itemType) => ({
  type: 'CLICK_ADD_NEW_ITEM',
  category,
  itemType
});

export const handleMoveElement = (categoryID, elementID, initialPosition, endPosition) => ({
  type: 'MOVE_ELEMENT',
  categoryID,
  elementID,
  initialPosition,
  endPosition
});

export const handleMoveElementFailure = (categoryID, elementID, initialPosition, endPosition) => ({
  type: 'MOVE_ELEMENT_FAILURE',
  categoryID,
  elementID,
  initialPosition,
  endPosition
})

export const handleEditElement = (categoryID, element) => ({
  type: 'SELECT_ELEMENT_FOR_EDIT',
  categoryID,
  element
});

export const handleDeleteElement = (categoryID ,elementID) => ({
  type: 'DELETE_ELEMENT',
  categoryID,
  elementID
});

export const deleteElementSuccess = (categoryID ,elementID) => ({
  type: 'DELETE_ELEMENT_SUCCESS',
  categoryID,
  elementID
});


