import { saveError, saveSuccess } from 'actions/form.base.actions';
import { createContent, updateContent } from 'services/api/content_requests';
import { subheadingValidations } from 'services/validations/subheading';

const canHandleAction = (action) => {
  if (action.type !== 'CREATE_FORM' && action.type !== 'UPDATE_FORM'){
    return false;
  }
  return (action.form.itemType === 'Subheading');
};

export const saveSubheadingMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a subheading
  if (!canHandleAction(action)) {
    return next(action)
  }

  const state = getState();

  const body = {
    subheading: state.form.values,
    tag_ids: state.form.tagIds || [],
    new_tag_names: state.form.newTagNames || []
  }

  let request;
  if (action.type === 'CREATE_FORM'){
    request = createContent(state.technique.id, state.form.categoryToAddItemTo.id, 'subheadings', body);
  } else if (action.type === 'UPDATE_FORM'){
    request = updateContent(state.technique.id, state.form.selectedCategoryId, 'subheadings', state.form.selectedElementId, body);
  }

  request
    .then(res => res.json())
    .then(res => dispatch(saveSuccess(res)))
    .catch(err => dispatch(saveError(err)));
};

export const subheadingFormValidationMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a subheading
  if (!canHandleAction(action)) {
    return next(action)
  }

  const subheading = action.form;

  let errors = { ...subheadingValidations(subheading.values) }

  const anyErrors = Object.values(errors).some(errs => errs.length > 0);

  if (anyErrors) {
    dispatch(saveError(errors))
  } else {
    return next(action)
  }
};
