import { moveElement, deleteElement } from 'services/api/element_requests';
import { deleteElementSuccess, handleMoveElementFailure } from '../../actions/techniques/techniques.actions';


export const moveElementMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === "MOVE_ELEMENT") {
    let request = moveElement(action.elementID, action.endPosition)

    request.then(res => {
      if (res.status === 422 || res.status === 500) {
        dispatch(handleMoveElementFailure({...action}));
      }
    })
  }

  return next(action)
};

export const deleteElementMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === "DELETE_ELEMENT") {
    deleteElement(action.elementID)
      .then(res => dispatch(deleteElementSuccess(action.categoryID, action.elementID)))
      .catch(err => console.log(err));
  }

  return next(action)
};
