import React from 'react';

import ElementWrapper from './ElementWrapper';

const Subheading = ({ element, dragHandleProps, handleDelete, handleEdit }) => {
  return (
    <ElementWrapper element={element} elementClass='element-subheading' dragHandleProps={dragHandleProps} handleEdit={handleEdit} handleDelete={handleDelete}>
      <h3>{element.content.title}</h3>
    </ElementWrapper>
  );
}

export default Subheading;
