export function present(value) {
  if(value === undefined || value === '') {
    return "can't be blank"
  }
}

export function length(value, additional_conditions) {
  const max_length = additional_conditions['max_length'];
  if (value.length > max_length) {
    return `can't be longer than ${max_length} characters`
  }
}

const build_validation = (validator, object, field) => {
  const additional_conditions = validator['additional_conditions'];
  if (additional_conditions){
    return validator['validation'](object[field], additional_conditions)
  } else {
    return validator['validation'](object[field]);
  }
}

export function runValidations(object, attributeValidators) {
  return Object.keys(attributeValidators).reduce((errors, field) => {
    errors[field] = attributeValidators[field].map(validator => build_validation(validator, object, field)).filter(n => n)
    return errors
  }, {})
}
