import React from 'react';
import { Button, ButtonGroup, Badge } from 'react-bootstrap';

const tagsFor = (element) => {
  if (element.tags) {
    return(
      <div className='mb-1'>
        {element.tags.map((tag) => { return <Badge className='mr-1' key={`badge_${element.id}_${tag.id}`} variant="dark">{tag.name}</Badge> })}
      </div>
    );
  }
}

const ElementWrapper = ({ element, elementClass, dragHandleProps, handleDelete, handleEdit, children }) => {
  return (
    <div className={`element ${elementClass}`}>
      <div className="element-drag" {...dragHandleProps}><i className={`fas fa-grip-vertical fa-fw fa-lg`}></i></div>
      <div className="element-content" id={`element-content-${element.id}`}>
        {tagsFor(element)}
        {children}
      </div>
      <div className="element-actions">
        <ButtonGroup aria-label="element actions">
          <Button id={`edit_element_${element.id}`} size="sm" variant="outline-secondary" onClick={() => handleEdit(element)}><i className={`fas fa-edit fa-fw`}></i></Button>
          <Button id={`delete_element_${element.id}`} data-confirm="Are you sure you want to delete this paragraph?" size="sm" variant="outline-danger" onClick={() => handleDelete(element.id)}><i className={`fas fa-trash fa-fw`}></i></Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

export default ElementWrapper;