import { saveError, saveSuccess } from 'actions/form.base.actions';
import { createContent, updateContent } from 'services/api/content_requests';
import { paragraphValidations } from 'services/validations/paragraph';

const canHandleAction = (action) => {
  if (action.type !== 'CREATE_FORM' && action.type !== 'UPDATE_FORM') {
    return false;
  }
  return (action.form.itemType === 'Paragraph');
};

export const saveParagraphMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a paragraph
  if (!canHandleAction(action)) {
    return next(action)
  }

  const state = getState();
  
  const body = {
    paragraph: state.form.values,
    tag_ids: state.form.tagIds || [],
    new_tag_names: state.form.newTagNames || []
  }

  let request;
  if (action.type === 'CREATE_FORM') {
    request = createContent(state.technique.id, state.form.categoryToAddItemTo.id, 'paragraphs', body)
  } else if (action.type === 'UPDATE_FORM') {
    request = updateContent(state.technique.id, state.form.selectedCategoryId, 'paragraphs', state.form.selectedElementId, body)
  }

  request
    .then(res => res.json())
    .then(res => dispatch(saveSuccess(res)))
    .catch(err => dispatch(saveError(err)));
};

export const paragraphFormValidationMiddleware = ({ dispatch, getState }) => next => action => {
  // Go to the next action if we're not saving or we're not a paragraph
  if (!canHandleAction(action)) {
    return next(action)
  }

  const paragraph = action.form;

  let errors = { ...paragraphValidations(paragraph.values) }

  const anyErrors = Object.values(errors).some(errs => errs.length > 0);

  if (anyErrors) {
    dispatch(saveError(errors))
  } else {
    return next(action)
  }
};