import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import SelectTags from './SelectTags';
import { updateFormValue, cancelEdit, createForm, updateForm } from 'actions/form.base.actions';

class SubheadingForm extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
  }

  componentDidMount() {
    this.titleRef.current.focus();
   }

  handleCreateSubmit = (e) => {
    e.preventDefault();
    this.props.createSubheadingForm(this.props.form, this.props.details)
  }

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.updateSubheadingForm(this.props.form, this.props.details)
  }

  render() {
    const { form, element, values = {}, editingElement=false, errors = {}, handleFormUpdate, cancelEdit } = this.props;
    return (
      <form noValidate onSubmit={editingElement === true ? this.handleUpdateSubmit : this.handleCreateSubmit} id='subheading-form'>
        <div className="card mb-3">
          <div className="card-header">{editingElement === true ? 'Edit subheading' : 'Add subheading'}</div>
          <div className="card-body">
            <Row>
              <Col md="6">
                <p>Tags</p>
                <SelectTags element={element}></SelectTags>
              </Col>
            </Row>
            <Form.Group as={Row} controlId="formSubheading" className="mb-0 mt-3">
              <Col md="12">
                <Form.Label>Title</Form.Label>
                <Form.Control ref={this.titleRef} type="string" placeholder="Enter subheading title" value={values.title} onChange={(e) => handleFormUpdate('title', e.target.value)} isInvalid={!!errors.title} />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </div>
          <div className="card-footer">
            <Row>
              <Col md="6">
                <Button variant="link" onClick={cancelEdit}>Cancel</Button>
              </Col>
              <Col md="6" className="d-flex justify-content-end">
                <Button variant="primary" type='submit'>{editingElement === true ? 'Update subheading' : 'Add subheading'}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    createSubheadingForm: createForm,
    updateSubheadingForm: updateForm,
    cancelEdit: cancelEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SubheadingForm)
