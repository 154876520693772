import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as trix from 'trix';

import "trix/dist/trix.css";
import 'stylesheets/trix';

import { updateFormValue, cancelEdit, createForm, updateForm } from 'actions/form.base.actions';
import { Form, Button, Row, Col } from 'react-bootstrap';
import SelectTags from './SelectTags';

class ParagraphForm extends React.Component {
  constructor(props) {
    super(props);

    trix.config.textAttributes.sup = { tagName: "sup", inheritable: true }
    trix.config.textAttributes.sub = { tagName: "sub", inheritable: true }
    trix.config.textAttributes.u = { tagName: "u", inheritable: true }

    this.textRef = React.createRef();
    this.trixInput = React.createRef();
  }

  componentDidMount() {
    this.trixInput.current.addEventListener("trix-change", this.handleTrixChange);
    this.trixInput.current.addEventListener("trix-initialize", this.addTrixSup);
    this.trixInput.current.addEventListener("trix-initialize", this.addTrixSub);
    this.trixInput.current.addEventListener("trix-initialize", this.addTrixU);
  }

  componentWillUnmount() {
    this.trixInput.current.removeEventListener('trix-change', this.handleTrixChange);
  }

  updateSelectedAttributes = (editor) => {
    var selectedAttributes = new Set;
    var selectedRange = editor.getSelectedRange();
    if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ }

    var selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces();
    selectedPieces.forEach(function (piece) {
      Object.keys(piece.getAttributes()).forEach(function (attribute) {
        selectedAttributes.add(attribute);
      })
    })
  }


  addTrixSup = (e) => {
    const element = e.target;
    let editor = element.editor;
    const toolbarElement = element.toolbarElement;
    let groupElement = toolbarElement.querySelector(".trix-button-group--text-tools");

    groupElement.insertAdjacentHTML("beforeend", '<button type="button" class="trix-button sup-button" title="Superscript" data-trix-attribute="sup"><sup>SUP</sup></button>');

    this.updateSelectedAttributes(editor);
    element.addEventListener("trix-selection-change", this.updateSelectedAttributes(editor));
  }

  addTrixSub = (e) => {
    const element = e.target;
    let editor = element.editor;
    const toolbarElement = element.toolbarElement;
    let groupElement = toolbarElement.querySelector(".trix-button-group--text-tools");

    groupElement.insertAdjacentHTML("beforeend", '<button type="button" class="trix-button sub-button" title="Subscript" data-trix-attribute="sub"><sub>SUB</sub></button>');

    this.updateSelectedAttributes(editor);
    element.addEventListener("trix-selection-change", this.updateSelectedAttributes(editor));
  }

  addTrixU = (e) => {
    const element = e.target;
    let editor = element.editor;
    const toolbarElement = element.toolbarElement;
    let groupElement = toolbarElement.querySelector(".trix-button-group--text-tools");

    groupElement.insertAdjacentHTML("beforeend", '<button type="button" class="trix-button underline-button" title="Underline" data-trix-attribute="u"><u>U</u></button>');

    this.updateSelectedAttributes(editor);
    element.addEventListener("trix-selection-change", this.updateSelectedAttributes(editor));
  }

  handleTrixChange = (e) => {
    this.props.handleFormUpdate('text', e.target.innerHTML);
  }

  handleCreateSubmit = (e) => {
    e.preventDefault();
    this.props.createParagraphForm(this.props.form, this.props.details)
  }

  handleUpdateSubmit = (e) => {
    e.preventDefault();
    this.props.updateParagraphForm(this.props.form, this.props.details)
  }
  
  render() {
    const { form, element, values = {}, editingElement = false, errors = {}, handleFormUpdate, cancelEdit } = this.props;

    return (
      <form noValidate onSubmit={editingElement === true ? this.handleUpdateSubmit : this.handleCreateSubmit} id='paragraph-form'>
        <div className="card mb-3">
          <div className="card-header">{editingElement === true ? 'Edit paragraph' : 'Add paragraph'}</div>
          <div className="card-body">
            <Row>
              <Col md="6">
                <p>Tags</p>
                <SelectTags
                  label="Tags"
                  element={element}
                />
              </Col>
            </Row>
            
            <div className='mt-5'>
              <div className="alert alert-info" role="alert">
                <p>You can add definitions for technical terms by using the following syntax. It is recommended you copy and paste it to minimise mistakes.</p>
                <p className='how-to-add-definition'>{"{ def title='keywords to define' text='Definition of keywords' }"}</p>
              </div>

              <trix-editor input="trix-text-area" ref={this.trixInput} class={!!errors.text ? 'is-invalid' : ''} value={values.text} />
              <Form.Control id='trix-text-area' type="hidden" value={values.text} isInvalid={!!errors.text} />
              <Form.Control.Feedback type="invalid">
                {errors.text}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="card-footer">
            <Row>
              <Col md="6">
                <Button variant="link" onClick={cancelEdit}>Cancel</Button>
              </Col>
              <Col md="6" className="d-flex justify-content-end">
                <Button variant="primary" type='submit'>{editingElement === true ? 'Update paragraph' : 'Add paragraph'}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
    values: state.form.values,
    errors: state.form.errors
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    handleFormUpdate: updateFormValue,
    createParagraphForm: createForm,
    updateParagraphForm: updateForm,
    cancelEdit: cancelEdit
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ParagraphForm)
