import React from 'react';
import { Dropdown } from 'react-bootstrap';

const NewItemDropdown = ({handleAddNewItem }) => {
  return (
    <div className="mb-3 d-flex justify-content-center">
      <Dropdown>
        <Dropdown.Toggle variant="outline-primary" className='btn-sm'>
          <i className={`fas fa-plus fa-fw`}></i>
          <span>Add new item</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleAddNewItem('Subheading')}>Subheading</Dropdown.Item>
          <Dropdown.Item onClick={() => handleAddNewItem('Paragraph')}>Paragraph</Dropdown.Item>
          <Dropdown.Item onClick={() => handleAddNewItem('Image')}>Image</Dropdown.Item>
          <Dropdown.Item onClick={() => handleAddNewItem('Video')}>Video</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default NewItemDropdown;
