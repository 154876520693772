import { patchRequest, postRequest } from './base_requests';

export function createContent(technique_id, category_id, content_type, body) {
  return postRequest({ url: `/content_manager/techniques/${technique_id}/categories/${category_id}/${content_type}`, body })
}

export function updateContent(technique_id, category_id, content_type, content_id, body) {
  return patchRequest({ url: `/content_manager/techniques/${technique_id}/categories/${category_id}/${content_type}/${content_id}`, body })
}


