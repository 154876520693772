import * as Sentry from "@sentry/browser";

// Notify Sentry of any js errors
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://4554da79e8a549d49d8705cd986cbab8@sentry.epigenesys.org.uk/49",
  });
}

export { Sentry };
export { SentryErrorBoundary } from "./SentryErrorBoundary";
